import { useState, useEffect, type SyntheticEvent } from 'react';

// types
import type { FAQAccordionPayload } from 'components/ContentElementsGdds/content-elements-payload';

// components
import { Accordion as NordicsFAQAccordion } from 'components/ContentElementsGdds/NordicsAccordion/faq-accordion';
import { GddsFAQAccordion } from './gdds-faq-accordion';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { useHash } from 'utils/hooks/useHash';
import { useXy } from 'utils/hooks/use-xy';
import { useNord } from 'utils/hooks/use-nord';
import { accordionClick } from './tracking-actions';

export function Accordion(props: Readonly<FAQAccordionPayload>) {
  const {
    items,
    openMode,
  } = props;

  const track = useTracking();
  const isNordics = useNord();
  const isXy = useXy();
  const [openedItems, setOpenedItems] = useState<number[]>([]);

  const { matchIndex } = useHash(items);

  useEffect(() => {
    if (openMode === 'all') {
      setOpenedItems(Array.from({ length: items.length }, (_, index) => index));
    }
    else if (openMode === 'first') {
      setOpenedItems([0]);
    } else setOpenedItems([]);
  }, [openMode, items.length]);


  useEffect(() => {
    const openIndex = matchIndex !== -1 && matchIndex;

    if (openIndex || openIndex === 0) {
      setOpenedItems([openIndex]);
    }
  }, [matchIndex]);

  const onClick = (e: SyntheticEvent<HTMLDivElement> | undefined, index: number) => {
    track.trackEvent(accordionClick('Clicks', e?.currentTarget.innerText));

    if (openedItems.includes(index)) {
      setOpenedItems(openedItems.filter((item) => item !== index));
    } else {
      setOpenedItems([...openedItems, index]);
    }
  };

  const toggleAllItems = () => {
    setOpenedItems(openedItems?.length > 0 ? [] :
      Array.from({ length: items.length }, (_, index) => index)
    );
  };


  if (isXy || isNordics) {
    return (
      <NordicsFAQAccordion
        {...props}
        openedItems={openedItems}
        updateOpenedItems={onClick}
      />
    );
  }

  return (
    <GddsFAQAccordion
      {...props}
      openedItems={openedItems}
      toggleAllItems={toggleAllItems}
      updateOpenedItems={onClick}
    />
  );
}
