import { trackTemplate } from 'utils/tracking';

/**
 * Accordion Click
 * @param string category Category name
 * @param string label Accordion item label
 */
export function accordionClick(category: string, label: string | undefined) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category,
      action: 'Accordion',
      label,
      value: '0',
    },
  });
}
