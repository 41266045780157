import { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

export function useURLChange() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [url, setUrl] = useState('');

  useEffect(() => {
    let currentUrl = pathname;
    if (searchParams.size > 0) {
      currentUrl += `?${searchParams.toString()}`;
    }
    currentUrl += window.location.hash;
    setUrl(currentUrl);
  }, [pathname, searchParams]);

  return url;
}
