import * as React from 'react';
import { generateSlug } from '../slug';
import { decodingContent } from '../decodingContent';
import { isEmpty } from '../is-empty';
import { useURLChange } from './use-url-change';

export function useHash(tabTitles: any[]) {
  const [hashes, setHashes] = React.useState<string[]>();
  const url = useURLChange();

  React.useEffect(() => {
    const hash = window.location.hash.split('#').at(1);
    if (hash) {
      setHashes(decodeURIComponent(hash).split('|'));
    }
  }, [url]);

  const matchIndex = React.useMemo(() => {
    if (isEmpty(tabTitles) || isEmpty(hashes)) {
      return -1;
    }

    return tabTitles.findIndex((t) => {
      const title = typeof t === 'string' ? t : t.title;
      const titlesIndex = hashes.findIndex((hash) => hash === generateSlug(decodingContent(title)));

      return titlesIndex > -1;
    });
  }, [hashes, tabTitles]);

  return { matchIndex, hashes };
}
