import Head from 'next/head';

import type { FAQPage, WithContext, Question } from 'schema-dts';

export function FAQPageSchema({
  items,
}: Readonly<{ items: { question: string; answer: string }[] }>) {
  const faqPageSchema = generateFAQPage(items);

  if (!faqPageSchema) return null;

  return (
    <Head>
      <script key="faq-page-ld-json" type="application/ld+json">
        {faqPageSchema}
      </script>
    </Head>
  );
}

function generateFAQPage(faqPageItems: { question: string; answer: string }[] | undefined) {
  if (!faqPageItems || faqPageItems.length === 0) return undefined;

  const mainEntity: Question[] = [];
  const faqPage: WithContext<FAQPage> = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
  };

  faqPageItems.forEach(({ question, answer }) => {
    const entry: Question = {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    };

    mainEntity.push(entry);
  });

  faqPage.mainEntity = mainEntity;

  return JSON.stringify(faqPage);
}
