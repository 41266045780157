import { useMemo } from 'react';
import { Link, Container, Row, Col, AccordionItem, InnerHtml } from '@geberit/gdds';

// styles
import styles from './accordion.module.scss';

// types
import type { FAQAccordionPayload } from 'components/ContentElementsGdds/content-elements-payload';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { FAQPageSchema } from 'components/json-linked-data/faq-page/faq-page-schema';

// utils
import { useSectionId } from 'utils/hooks/use-section-id';
import { buildSize, gridSizes } from 'utils/gridSize';
import { decodingContent } from 'utils/decodingContent';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useTwyford } from 'utils/hooks/use-twyford';

export function GddsFAQAccordion(props: Readonly<FAQAccordionPayload>) {
  const {
    title,
    subtitle,
    anchor,
    titleInAnchor,
    items,
    contentIndex,
    catalogName,
    isSectionReference,
    openedItems,
    toggleAllItems,
    updateOpenedItems,
  } = props;

  const sectionId = useSectionId(title || '', anchor || '', !!titleInAnchor, contentIndex || 0);
  const isTwyford = useTwyford();
  const translate = useTranslationFunction();

  const linkAction = useMemo(
    () =>
      translate((openedItems?.length ?? 0) > 0 ? 'group_accordion_closeall' : 'group_accordion_openall'),
    [openedItems, translate],
  );

  const gridSize = isTwyford ? gridSizes.full : gridSizes.twoThird;

  return (
    <section id={sectionId}>
      <FAQPageSchema items={items} />
        <div
          className={styles.accordion}
          data-preview-id={catalogName && !isSectionReference ? `#${catalogName}` : undefined}
        >
          <Container maxContentWidth={buildSize(gridSize)}>
            <Headline
              isFlexItem
              variant={Formats.h3}
              tag={Formats.h2}
              title={title}
              subtitle={subtitle}
            />
            <Row>
              <Col>
                  <Link
                    onClick={() => toggleAllItems?.()}
                    className={styles.handleLink}
                    hasArrow
                    stylingType="primary"
                    alignByContent="right"
                    standardFontSize={false}
                  >
                    <span>{linkAction}</span>
                  </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                {items.map((item, index) => {
                  let open = false;

                  if (openedItems?.length === items.length || openedItems?.includes(index)) {
                    open = true;
                  }

                  return (
                    <div data-preview-id={isSectionReference ? '' : `#${index}`} key={item.question}>
                      <div
                        data-preview-id={item.catalogName ? `#${item.catalogName}` : undefined}
                        className="accordionItem"
                      >
                        <AccordionItem
                          iconAlignment="right"
                          title={decodingContent(item.question)}
                          opened={open}
                          onTitleClick={(e) => updateOpenedItems?.(e, index)}
                          fontSize="p1"
                          titleBold
                        >
                          <Container>
                            <Row>
                              <InnerHtml as="div" content={item.answer} />
                            </Row>
                          </Container>
                        </AccordionItem>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </div>
    </section>
  );
}
