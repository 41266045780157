import key from 'weak-key';
import { Container, Row, Col, AccordionItem, InnerHtml } from '@geberit/gdds';

// styles
import styles from './accordion.module.scss';

// types
import type { FAQAccordionPayload } from 'components/ContentElementsGdds/content-elements-payload';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { FAQPageSchema } from 'components/json-linked-data/faq-page/faq-page-schema';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { useSectionId } from 'utils/hooks/use-section-id';
import { buildSize, gridSizes } from 'utils/gridSize';
import { decodingContent } from 'utils/decodingContent';
import { previewId } from 'utils/preview-id';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useThemeName } from 'utils/hooks/use-theme';
import { THEME_NAMES } from 'themes';
import { useXy } from 'utils/hooks/use-xy';

export function Accordion({
  title,
  subtitle,
  anchor,
  titleInAnchor,
  items = [],
  contentIndex,
  catalogName,
  isSectionReference,
  openedItems,
  updateOpenedItems,
}: Readonly<FAQAccordionPayload>) {
  const sectionId = useSectionId(title || '', anchor || '', !!titleInAnchor, contentIndex || 0);
  const theme = useThemeName();
  const isNordics = theme === THEME_NAMES.NORD;
  const isXy = useXy();

  return (
    <section id={sectionId}>
      <FAQPageSchema items={items} />
      <div className={classNameBuilder(styles.accordion, styles.faqAccordion)}>
        <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
          <Headline
            tag={Formats.h2}
            {...(isNordics && { variant: Formats.h3 })}
            title={title}
            subtitle={subtitle}
            titlePreviewId="#st_title"
            subtitlePreviewId="#st_subtitle"
          />

          <Row>
            <Col size={[0, 0, 2]} />
            <Col size={[4, 8, 8]}>
              <div
                {...previewId(catalogName && !isSectionReference ? `#${catalogName}` : undefined)}
              >
                {items.map((accordionItem, index) => {
                  let open = false;

                  if (openedItems?.length === items.length || openedItems?.includes(index)) {
                    open = true;
                  }

                  return (
                    <div key={key(accordionItem)} className={styles.accordionItem}>
                      <div {...previewId(`#${index}`)}>
                        <AccordionItem
                          iconAlignment="right"
                          title={decodingContent(accordionItem.question)}
                          titlePreviewId="#st_accordionLabel"
                          contentPreviewId="#st_content"
                          opened={open}
                          onTitleClick={(e) => updateOpenedItems?.(e, index)}
                          fontSize="p1"
                          titleBold
                          {...(isXy && { tag: Formats.h3 })}
                        >
                          <div className={styles.accordionItemContent}>
                            <InnerHtml content={accordionItem.answer} as="div" />
                          </div>
                        </AccordionItem>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
