import { useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import slugify from 'slugify';

// types
import type { FAQAccordionPayload } from '../content-elements-payload';

// components
import { Title } from '../Title/Title';
import { TitleFormats } from '../Title/title.types';
import { AccordionContent } from './AccordionContent';
import { Accordion as GDDSFAQAccordion } from 'components/ContentElementsGdds/Accordion/faq-accordion';
import { Accordion as NordicsFAQAccordion } from 'components/ContentElementsGdds/NordicsAccordion/faq-accordion';
import { FAQPageSchema } from 'components/json-linked-data/faq-page/faq-page-schema';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { THEME_NAMES } from 'themes';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';

import { trackingOnChange } from 'utils/trackAccordion';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useThemeName } from 'utils/hooks/use-theme';
import { isEmpty } from 'utils/is-empty';
import { useGroup } from 'utils/hooks/use-group';

export function AccordionContainer(
  props: Readonly<Omit<FAQAccordionPayload, 'titleInAnchor'> & { titleInAnchor: boolean }>,
) {
  const {
    title,
    anchor,
    subtitle,
    items,
    openMode,
    contentIndex,
    titleInAnchor,
    catalogName,
    isSectionReference,
  } = props;
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const theme = useThemeName();
  const track = useTracking();
  const { trackAccordionOpening } = useTracking2();
  const isGroup = useGroup();
  const [openAccordions, setOpenAccordions] = useState([]);

  if (isEmpty(items)) {
    return null;
  }

  if (theme === THEME_NAMES.NORD || theme === THEME_NAMES.KOLO) {
    return <NordicsFAQAccordion {...props} />;
  }

  if (isGroup) {
    return <GDDSFAQAccordion {...props} />;
  }

  const preOpenItems = items
    .filter((_, index) => openMode === 'all' || (index === 0 && openMode === 'first'))
    .map((item, index) => `${index}-${slugify(item.question)}`);

  return (
    <>
      <FAQPageSchema items={items} />
      <div className="grid-container" id={sectionId}>
        <Title
          title={title}
          subtitle={subtitle}
          Format={TitleFormats.h2}
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
          isSectionReference={isSectionReference}
          formatClassName="h3"
        />
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          className="c-accordion c-accordion--faq"
          preExpanded={preOpenItems}
          onChange={(uuid) => {
            trackingOnChange(track, setOpenAccordions, openAccordions, undefined, uuid);
            trackAccordionOpening(uuid);
          }}
        >
          <div data-preview-id={catalogName && !isSectionReference ? `#${catalogName}` : undefined}>
            {items.map((item, index) => {
              return (
                <div
                  key={`${index}-${item.question}`}
                  data-preview-id={!isSectionReference ? `#${index}` : undefined}
                >
                  <AccordionContent
                    index={index}
                    uuid={`${index}-${slugify(item.question)}`}
                    title={item.question}
                    content={<InnerHtml content={item.answer} as="div" />}
                    catalogName={item.catalogName}
                    isSectionReference={isSectionReference}
                    sourceComponent="faq-accordion"
                  />
                </div>
              );
            })}
          </div>
        </Accordion>
      </div>
    </>
  );
}
